<template>
  <form class="needs-validation modal-form active" :class="{'was-validated': error}" rel="log-in" @submit.prevent="login" novalidate>
    <div class="modal-form__body">
      <div class="form-item">
        <input v-validate.immediate="'required|email'" :class="{invalid: errors.has('email')}" name="email" type="email" :disabled="loading" v-model="auth.username" class="form-control" id="form-email" placeholder="Введите e-mail"  required />
      </div>
      <div class="form-item">
        <input v-validate.immediate="'required|min:6'" :class="{invalid: errors.has('password')}" name="password" type="password" :disabled="loading" v-model="auth.password" class="form-control" id="form-pass" placeholder="Введите пароль" required />
      </div>
      <form-error v-if="error" :error="error" />
      <div class="modal-authorization__remember d-flex justify-content-between align-items-center">
        <div class="checkbox-wrapper">
          <div class="checkbox">
            <input type="checkbox" v-model="auth.remember" id="remember"/>
            <label for="remember" class="m-0">Запомнить меня</label>
          </div>
        </div>
        <a href="#" rel="restore-pass" class="nav-form content_18 content_red content_underline">Забыли пароль?</a>
      </div>
    </div>
    <div class="modal-form__footer">
      <div class="m-0 w-100 modal-footer-authorization modal-footer-animation">
        <div class="row align-items-center">
          <div class="col">
            <div class="content_18">Нет аккаунта? <router-link to="/sign-up" class="nav-form content_red content_underline modal-register">Зарегистрироваться</router-link>
            </div>
          </div>
          <div class="col-auto">
            <button type="submit" class="link-target"><span>войти</span></button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import ApiService from "../../services/api.service";
import {eventBus} from "@/main";
import FormError from "@/components/FormError.vue";
import {mapActions, mapGetters} from "vuex";
import lkPathMixin from "@/mixins/lkPathMixin";

export default {
  name: "LoginForm",
  components: {FormError},
  mixins: [lkPathMixin],
  data() {
    return {
      error: null,
      loading: false,
      auth: {
        username: null,
        password: null,
        remember: false,
        redirect: this.$route.params.redirect
      }
    }
  },
  methods: {
    ...mapActions(['getOrganization', 'getNotes', 'getMspForsageOperator', 'getMspForsageOrders']),
    async login() {

      let result = await this.$validator.validateAll()
      this.error = null

      if(!result) {
        this.error = new Error('Проверьте правильность ввода полей формы')
        return;
      }

      this.loading = true
      let resp = {}
      try {
        resp = (await ApiService.login(
            this.auth.username,
            this.auth.password,
            this.auth.remember,
            this.auth.redirect)
        )
        if(resp.status && resp.status === 200) {
          resp = (await ApiService.getMe(this.mspForsageOrderRequest))
          await this.getOrganization()
          await this.getNotes()
          if(this.$hasRole('ROLE_OPERATOR_MSP_MBA')) {
            await this.getMspForsageOperator();
          }
          if(this.$hasRoles(['ROLE_FORSAGE', 'ROLE_MSP'])) {
            await this.getMspForsageOrders();
          }
        }
        this.loading = false
      } catch (err) {
        this.loading = false
        this.error = err
        return;
      }

      if(resp.status && resp.status !== 200) {
        this.error = resp
        this.loading = false
        return;
      }

      await eventBus.$emit('user:upd')

      if(this.$isOLdLk()) {
        localStorage.removeItem('access:path')
        window.location.href = '/profile/settings/personal'
        return;
      } else if(localStorage.getItem('access:path')) {
        await this.$router.push({
          path: localStorage.getItem('access:path'),
        }).catch(() => {})
      } else {
        await this.$router.push({
          path: this.lkPath,
        }).catch(() => {})
      }
      localStorage.removeItem('access:path')
    },
  },
  mounted() {
    this.$parent.init()
  },
  computed: {
    ...mapGetters(['mspForsageOrderRequest', 'selectionRole'])
  }
}
</script>

<style scoped>

</style>